import axios from 'axios';
import 'moment-timezone';
import * as React from 'react';
// import * as ReactDatetime from "react-datetime";
import * as ReactMarkdown from 'react-markdown';
// import Moment from 'react-moment';

type ContentItem = {
  id: string;
  title: string;
  author: string;
  tags: Tag[];
  body: string;
  publish_date: string;
};

type Tag = {
  tag_name: string;
};

interface State {
  contents: ContentItem[];
}

interface TitleProps {
  title: string;
  author: string;
  tags: Tag[];
  publish_date: string;
}

class ContentTitle extends React.Component<TitleProps> {
  public render() {
    const moment = require(`moment-timezone`);
    const tags = this.props.tags.map((t: Tag, i: number) => {
      return (
        <div key={i} className="tag">
          #{t.tag_name}
        </div>
      );
    });
    return (
      <div className="content-header">
        <div className="content-title">{this.props.title}</div>
        <div className="content-tags">{tags}</div>
        <div className="content-meta">
          <div className="content-author">@{this.props.author}</div>
          <div className="content-datetime">
            {moment
              .unix(this.props.publish_date)
              .tz('Asia/Tokyo')
              .format('YYYY-MM-DD HH:mm')}
          </div>
        </div>
      </div>
    );
  }
}

class Contents extends React.Component {
  public state: State = {
    contents: [],
  };
  constructor(props: any) {
    super(props);
    // axios({
    //     url: "/query.graphql"
    // }).then(query => {
    axios({
      method: 'POST',
      url: process.env.REACT_APP_API_URL,
      data: {
        query: `query {
					user{
						description
						email
						name
						nick_name
					}
					contents {
						id
						author
						title
						publish_date
						tags {
							tag_name
						}
						body
					}
				}`,
      },
    }).then(results => {
      this.setState({
        contents: [].concat(results.data.data.contents),
      });
    });
  }

  public render() {
    const contents = this.state.contents.map((content: any, i: number) => {
      return (
        <div key={i} className="content">
          <ContentTitle
            title={content.title}
            author={content.author}
            publish_date={content.publish_date}
            tags={content.tags}
          />
          <div className="content-text">
            <ReactMarkdown source={content.body} />
          </div>
        </div>
      );
    });
    return <div className="contents">{contents}</div>;
  }
}

export default Contents;
