import * as React from 'react';
import * as ReactDOM from 'react-dom';
import * as ReactGA from 'react-ga';
import App from './App';
import './index.css';
import registerServiceWorker from './registerServiceWorker';

const code = process.env.REACT_APP_GA_CODE;
typeof code === 'string' ? ReactGA.initialize(code) : console.log("REACT_APP_GA_CODE not set");

ReactDOM.render(
  <App />,
  document.getElementById('root') as HTMLElement
);
registerServiceWorker();