import Button from '@material-ui/core/Button';
// import ListItemIcon from '@material-ui/core/ListItemIcon';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import MenuIcon from '@material-ui/icons/Menu';

import * as React from 'react';

class SimpleMenu extends React.Component {
  public state = {
    anchorEl: null,
  };

  public handleClick = (event: any) => {
    this.setState({anchorEl: event.currentTarget});
  };

  public handleClose = () => {
    this.setState({anchorEl: null});
  };

  public openTwitter = () => {
    location.href = 'https://twitter.com/trrrrrys';
  };
  public openGitHub = () => {
    location.href = 'https://github.com/trrrrrys';
  };

  public render() {
    const {anchorEl} = this.state;

    return (
      <div className="App-header-menu">
        <Button
          aria-owns={anchorEl ? 'simple-menu' : undefined}
          aria-haspopup="true"
          onClick={this.handleClick}>
          <MenuIcon />
        </Button>
        <Menu
          id="simple-menu"
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={this.handleClose}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
          getContentAnchorEl={null}>
          <MenuItem onClick={this.handleClose}>Profile</MenuItem>
          <MenuItem onClick={this.openTwitter}>Twitter</MenuItem>
          <MenuItem onClick={this.openGitHub}>Github</MenuItem>
        </Menu>
      </div>
    );
  }
}

export default SimpleMenu;
