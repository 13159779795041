import * as React from 'react';

class Timeline extends React.Component {
  public render() {
    return (
		<div className="timeline">
      <a href="https://twitter.com/trrrrrys?ref_src=twsrc%5Etfw"
        className="twitter-timeline"
		// data-lang="ja"
		// data-width="15%"
		data-height="100%"
		data-theme="light"
		data-link-color="#19CF86"
		data-show-count="false">Tweets by trrrrrys</a>
		</div>
    );
  }
}
export default Timeline;

