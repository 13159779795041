import * as React from 'react';
// import * as ReactMarkdown from 'react-markdown'

import Contents from './components/content';
import Header from './components/header';
import Timeline from './components/tweets';

import './App.css';
import './Code.css';

class App extends React.Component {
  public render() {
    return (
      <div>
        <Header />
        <div className="app-contents">
          <div className="sidebar" />
          <Contents />
          <Timeline />
        </div>
      </div>
    );
  }
}

export default App;
