import * as React from 'react';
import Menu from './Menu';

class Header extends React.Component {
    public render() {
        return(
            <div className="App-header">
                <Menu />
                <div className="App-title">
                    <a href="/" style={{ textDecoration: 'none', color:'#000'}}>ぶろぐ</a>
                </div>
            </div>
            );
        };
}

export default Header